import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHouseLock,
  faUnlink,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import FileSvg from "../../../../assets/svg/new/FileSvg";
import { affecterExaminateur, organisme_API } from "../../../../service/config";
import SelectPower from "../../../../outils/Select/SelectPower";

export default function AffectationExaminateur({
  row,
  centre,
  setrecharger,
  load,
}) {
  const [statusModif, setStatusModif] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [choix,setchoix] = useState({})
  const [error, setError] = useState({});
  const [loadButton, setloadbutton] = useState(false);

  const changer = async () => {
    try {
      setloadbutton(true);
      await axios
        .put(
          affecterExaminateur +
            "/" +
            row.id_memb +
            "?id_org=" +
            formValues.id_org
        )
        .then((reponse) => {
            console.log(reponse)
          toast.success("centre mis à jour !");
          setloadbutton(false);
          setStatusModif(!statusModif);
          setrecharger(Math.floor(Math.random() * 11));
        })
        .catch((error) => {
          console.log(error);
          toast.error("un erreur est survenu !");
          setStatusModif(!statusModif);
          setloadbutton(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const DefaultValue = () => {
   let fn = centre.filter((el,i)=> el.id_org === row.id_org)
   if (fn[0]) {
    setchoix({label:fn[0].nom_org,value:fn[0].id_org})
   }else {
    setchoix(undefined)
   }
  }
useEffect(()=>{
  DefaultValue()

},[load])

  return (
    <>
      <Tag style={{cursor:"pointer"}}  severity={"info"} onClick={() => setStatusModif(true)} value={choix ? choix.label : "+ Nouvelle affectation"}></Tag>
      <Modal
        show={statusModif}
        onHide={() => {
          setStatusModif(!statusModif);
          setloadbutton(false);
        }}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        centered
        size={"lg"}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <img src={FileSvg.schoolRafik} alt="examinateur" />
            </div>
            <div className="col-6 align-self-center">
            <h3 className="my-3">Affectation de l'examinateur</h3>
              <SelectPower
                error={error}
                formValues={formValues}
                label={"nom_org"}
                value={"id_org"}
                placeholder={"choisir centre"}
                options={centre}
                load={load}
                name={"id_org"}
                setError={setError}
                setFormValues={setFormValues}
                className="w-75"
                choix={choix}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex ">
       {formValues.id_org && <Button
            variant="primary"
            disabled={loadButton}
            onClick={() => changer()}
          >
            Enregistrer
          </Button>}
          <Button
            variant="secondary"
            onClick={() => setStatusModif(!statusModif)}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
