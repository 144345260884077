import { useEffect, useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import SelectFinal from "../../../outils/Select/SelectFinal";
import SelectFinalOne from "../../../outils/Select/SelectFinalOne";

const ApprenantCategory = ({categ,setlistcat,listcat,name, options}) => {
  // Données initiales
  const [categories, setCategories] = useState(
   categ || []
  );
  const [error, setError] = useState({});

  // State pour gérer l'ajout/modification
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ id_categ: null, categ: "", date_aquis: "" });

  // Ouvre la modal (ajout ou édition)
  const handleShow = (category = null) => {
    if (category) {
      setFormData(category);

    } else {
      setFormData({ id_categ: null, categ: "", date_aquis: "" });
    }
    setShowModal(true);
  };

  // Ferme la modal
  const handleClose = () => setShowModal(false);

  // Gère les changements de formulaire
  const handleChange = (e) => {
   
      setFormData({ ...formData, [e.target.name]: e.target.value });
    
  };

  // Ajout ou mise à jour
  const handleSubmit = () => {
    if (formData.id_categ) {
      // Mise à jour
      const categ = options.find(cat => cat.value == formData.id_categ)
      formData.categ = categ?.label
   
       console.log(categ)
       console.log(formData)

       if (!listcat.some(cat => cat.id_categ === formData.id_categ)) {
        // Pas de doublon, on peut ajouter l'élément
        setlistcat([...listcat, formData]);
      } else {
        alert("Doublon détecté, l'élément ne sera pas ajouté.");
      }
    } 
    console.log(listcat)
    handleClose();
  };

  
  // Suppression
  const handleDelete = (id) => {
    setlistcat(listcat.filter((cat) => cat.id_categ !== id));
    
  };


  
  return (
    <div className="container mt-4">
     <div className="d-flex justify-content-between">
     <div>
     <label className="form-label">Catégories Acquis</label>
     </div>
     <div>
     <Button variant="primary"  onClick={() => handleShow()}>+ Ajouter</Button>
     </div>
     </div>
      
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Catégorie</th>
            <th>Date d'acquisition</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {listcat.map((category) => (
            <tr key={category.id_categ}>
              <td>{category.id_categ}</td>
              <td>{category.categ}</td>
              <td>{category.date_aquis}</td>
              <td>
                {/* <Button variant="warning" size="sm" onClick={() => handleShow(category)}>Modifier</Button>{' '} */}
                <Button variant="danger" size="sm" onClick={() => handleDelete(category.id_categ)}>Supprimer</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal d'ajout/modification */}
      <Modal className="modal-blur fade" centered size="sm" show={showModal} onHide={handleClose}>
        <Modal.Header className="border bg-light" closeButton>
          <Modal.Title>{formData.id_categ ? "Modifier la catégorie" : "Ajouter une catégorie"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border bg-light">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Catégorie</Form.Label>
              <SelectFinalOne
                                           options={options}
                                           load={false}
                                           value={"value"}
                                           label={"label"}
                                           placeholder="choix catégorie"
                                           setFormValues={setFormData}
                                           formValues={formData}
                                           setError={setError}
                                           error={error}
                                           name={"id_categ"}
                                           max={7}
                                           
                                         />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date d'acquisition</Form.Label>
              <Form.Control type="date" name="date_aquis" value={formData.date_aquis} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border bg-light">
          <Button variant="secondary" onClick={handleClose}>Annuler</Button>
          <Button variant="success" onClick={handleSubmit}>
            {formData.id_categ ? "Mettre à jour" : "Ajouter"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ApprenantCategory;
