export const nationalite = [
    {
        "value": "malagasy",
        "label": "Malagasy"
    },
    {
        "value": "afghane",
        "label": "Afghane"
    },
    {
        "value": "albanaise",
        "label": "Albanaise"
    },
    {
        "value": "algérienne",
        "label": "Algérienne"
    },
    {
        "value": "allemande",
        "label": "Allemande"
    },
    {
        "value": "américaine",
        "label": "Américaine"
    },
    {
        "value": "andorrane",
        "label": "Andorrane"
    },
    {
        "value": "angolaise",
        "label": "Angolaise"
    },
    {
        "value": "antiguaise-et-barbudienne",
        "label": "Antiguaise-et-Barbudienne"
    },
    {
        "value": "argentine",
        "label": "Argentine"
    },
    {
        "value": "arménienne",
        "label": "Arménienne"
    },
    {
        "value": "australienne",
        "label": "Australienne"
    },
    {
        "value": "autrichienne",
        "label": "Autrichienne"
    },
    {
        "value": "azerbaïdjanaise",
        "label": "Azerbaïdjanaise"
    },
    {
        "value": "bahaméenne",
        "label": "Bahaméenne"
    },
    {
        "value": "bahreïnienne",
        "label": "Bahreïnienne"
    },
    {
        "value": "bangladaise",
        "label": "Bangladaise"
    },
    {
        "value": "barbadienne",
        "label": "Barbadienne"
    },
    {
        "value": "belge",
        "label": "Belge"
    },
    {
        "value": "bélizienne",
        "label": "Bélizienne"
    },
    {
        "value": "béninoise",
        "label": "Béninoise"
    },
    {
        "value": "bhoutanaise",
        "label": "Bhoutanaise"
    },
    {
        "value": "biélorusse",
        "label": "Biélorusse"
    },
    {
        "value": "birmane",
        "label": "Birmane"
    },
    {
        "value": "bolivienne",
        "label": "Bolivienne"
    },
    {
        "value": "bosnienne",
        "label": "Bosnienne"
    },
    {
        "value": "botswanaise",
        "label": "Botswanaise"
    },
    {
        "value": "brésilienne",
        "label": "Brésilienne"
    },
    {
        "value": "britannique",
        "label": "Britannique"
    },
    {
        "value": "brunéienne",
        "label": "Brunéienne"
    },
    {
        "value": "bulgare",
        "label": "Bulgare"
    },
    {
        "value": "burkinabé",
        "label": "Burkinabé"
    },
    {
        "value": "burundaise",
        "label": "Burundaise"
    },
    {
        "value": "cambodgienne",
        "label": "Cambodgienne"
    },
    {
        "value": "camerounaise",
        "label": "Camerounaise"
    },
    {
        "value": "canadienne",
        "label": "Canadienne"
    },
    {
        "value": "cap-verdienne",
        "label": "Cap-Verdienne"
    },
    {
        "value": "centrafricaine",
        "label": "Centrafricaine"
    },
    {
        "value": "chilienne",
        "label": "Chilienne"
    },
    {
        "value": "chinoise",
        "label": "Chinoise"
    },
    {
        "value": "chypriote",
        "label": "Chypriote"
    },
    {
        "value": "colombienne",
        "label": "Colombienne"
    },
    {
        "value": "comorienne",
        "label": "Comorienne"
    },
    {
        "value": "congolaise",
        "label": "Congolaise"
    },
    {
        "value": "costaricaine",
        "label": "Costaricaine"
    },
    {
        "value": "croate",
        "label": "Croate"
    },
    {
        "value": "cubaine",
        "label": "Cubaine"
    },
    {
        "value": "danoise",
        "label": "Danoise"
    },
    {
        "value": "djiboutienne",
        "label": "Djiboutienne"
    },
    {
        "value": "dominicaine",
        "label": "Dominicaine"
    },
    {
        "value": "égyptienne",
        "label": "Égyptienne"
    },
    {
        "value": "équatorienne",
        "label": "Équatorienne"
    },
    {
        "value": "espagnole",
        "label": "Espagnole"
    },
    {
        "value": "estonienne",
        "label": "Estonienne"
    },
    {
        "value": "éthiopienne",
        "label": "Éthiopienne"
    },
    {
        "value": "finlandaise",
        "label": "Finlandaise"
    },
    {
        "value": "française",
        "label": "Française"
    },
    {
        "value": "gabonaise",
        "label": "Gabonaise"
    },
    {
        "value": "géorgienne",
        "label": "Géorgienne"
    },
    {
        "value": "ghanéenne",
        "label": "Ghanéenne"
    },
    {
        "value": "grecque",
        "label": "Grecque"
    },
    {
        "value": "guinéenne",
        "label": "Guinéenne"
    },
    {
        "value": "haïtienne",
        "label": "Haïtienne"
    },
    {
        "value": "hondurienne",
        "label": "Hondurienne"
    },
    {
        "value": "hongroise",
        "label": "Hongroise"
    },
    {
        "value": "indienne",
        "label": "Indienne"
    },
    {
        "value": "indonésienne",
        "label": "Indonésienne"
    },
    {
        "value": "iranienne",
        "label": "Iranienne"
    },
    {
        "value": "irakienne",
        "label": "Irakienne"
    },
    {
        "value": "irlandaise",
        "label": "Irlandaise"
    },
    {
        "value": "islandaise",
        "label": "Islandaise"
    },
    {
        "value": "italienne",
        "label": "Italienne"
    },
    {
        "value": "ivoirienne",
        "label": "Ivoirienne"
    },
    {
        "value": "jamaïcaine",
        "label": "Jamaïcaine"
    },
    {
        "value": "japonaise",
        "label": "Japonaise"
    },
    {
        "value": "jordannienne",
        "label": "Jordanienne"
    },
    {
        "value": "kazakhe",
        "label": "Kazakhe"
    },
    {
        "value": "kényane",
        "label": "Kényane"
    },
    {
        "value": "kirghize",
        "label": "Kirghize"
    },
    {
        "value": "kiribatienne",
        "label": "Kiribatienne"
    },
    {
        "value": "koweïtienne",
        "label": "Koweïtienne"
    },
    {
        "value": "laotienne",
        "label": "Laotienne"
    },
    {
        "value": "lesothane",
        "label": "Lesothane"
    },
    {
        "value": "lettonne",
        "label": "Lettonne"
    },
    {
        "value": "libanaise",
        "label": "Libanaise"
    },
    {
        "value": "libérienne",
        "label": "Libérienne"
    },
    {
        "value": "libyenne",
        "label": "Libyenne"
    },
    {
        "value": "lituanienne",
        "label": "Lituanienne"
    },
    {
        "value": "luxembourgeoise",
        "label": "Luxembourgeoise"
    },
    {
        "value": "macédonienne",
        "label": "Macédonienne"
    },
   
    {
        "value": "malaisienne",
        "label": "Malaisienne"
    },
    {
        "value": "malienne",
        "label": "Malienne"
    },
    {
        "value": "marocaine",
        "label": "Marocaine"
    },
    {
        "value": "mexicaine",
        "label": "Mexicaine"
    }
]