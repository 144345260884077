import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useCategoryOptions = () => {
  const category = useSelector(state => state.categ);

console.log(category)
  const options = useMemo(() => {
    if (category) {
      return category.map(element => ({
        label: element.label,
        value: element.value,
      }));
    }
    return [];
  }, [category]);

  return options;
};
