import React from 'react'
import image from '../../../assets/image/imgtest/image'

export default function Partenaire() {
  return (
    <div className='container-lg container-fluid my-3 mt-5 py-3 pb-5'>
        {/* <div className='titre text-center m-4'><p className='h1'><div className="vr w-2 bg-primary"></div> Partenaires <div className="vr w-2 bg-primary"></div> </p></div> */}
            <div className='titre text-center m-4'><p className='h3'>Projet en partenariat avec</p></div>
            <div className='d-flex flex-wrap justify-content-center gap-5'>
                <div className='d-flex flex-column justify-content-center text-center effet p-2'><a href='https://www.facebook.com/ATTMada' target={"_blank"} className="text-decoration-none"><img className="anime-effet" src={image.att} height="100"/></a></div>
                <div className='d-flex flex-column justify-content-center text-center effet p-2'><a href='https://www.facebook.com/ATTMada' target={"_blank"} className="text-decoration-none"><img className="anime-effet" src={image.mtm} height="96"/></a></div>
                <div className='d-flex flex-column justify-content-center text-center effet p-2'><a href='https://www.facebook.com/ATTMada' target={"_blank"} className="text-decoration-none"><img className="anime-effet" src={image.pact} height="100"/></a></div>
                <div className='d-flex flex-column justify-content-center text-center effet p-2'><a href='https://www.facebook.com/ATTMada' target={"_blank"} className="text-decoration-none"><img className="anime-effet" style={{transform:"translate(0,5px) scale(1.27)"}} src={image.metfp} height="100"/></a></div>
            </div>
    </div>
  )
}
